
.headerbgcolor{
  background-color: #FEF4E6;
}

.herobg{
  background-color: white; 
}

.heroimg{
  width: 80%;
}
.all-fields{
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  margin: 100px 0px ;
}
.each-field{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center; 
  cursor: pointer;
}
.field-icon-box{
  display: flex;
  justify-content: center;
  opacity: 50%; 
}
.field-icon{
 width: 15%;
}
.field-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 700px) {

  .all-fields{
   display: flex;
   flex-direction: column;
  }
  .field-icon{
   width: 100%;
  }
  .field-icon-box{
    width: 60px;
    height: 60px;
    padding-right: 0px !important;
  }
  .each-field{
    margin: 10px 0px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: space-around;
    width: 350px;
    
  }

}










body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.logo-link{
  width: 20% !important;

}
.logo-img{
 display: flex;
 align-items: center;
 cursor: pointer;
}
.header-page:after {
  display:block;
  content: '';
  border-bottom: solid 3px #001322;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}
.header-page:hover:after { transform: scaleX(1); }

.navActive{
  border-bottom: solid 3px #001322;  
}



.HeroText:hover {
  --c: #1583FF; 
  
  color: #0000;
  background: 
    linear-gradient(90deg,#fff 50%,var(--c) 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) 100% no-repeat;
  -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
  transition: 0.5s;
}

.HeroHeadTitle:hover{
  --c: #1583FF; 
  
  color: #0000;
  background: 
    linear-gradient(90deg,#fff 50%,var(--c) 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) 100% no-repeat;
  -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
  transition: 0.5s;
} 


.about-img-grid{
  padding-top: 0 !important;
}


.cardimg{
opacity: 50%;
}
.doingCard{
  transition: transform .2s;
  cursor: pointer;
}
.doingCard:hover{
  transform: scale(1.1);
}


.grid-bottom-right{
  padding-top: 40px !important;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgb(255, 255, 255);
}
.containerField{
  position: relative;
}

.containerField:hover .overlay {
  opacity: 1;
}

.text {
  color: rgb(150, 150, 150);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}




.img-container{
  width: 80px;
  height: 80px;
 
  
}
.userimg{
  border-radius: 50%;

}
.userParrent{
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
}
.CarouselIMg{

  background-color: #001322;
}
.slider{
  margin: 50px;
 text-align: center;
}


.companyLogo{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 100px 0px;
}
.logoImg{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform .2s;
}
.logoImg:hover{
  transform: scale(1.5);
}


.contactInfo{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-items: center; */
  height: 250px;
  margin-left: 1px;
}

.Info{
  display: flex;
  align-items: center;
  width: 60%;
}
.field{
  display: flex !important
  ;
}
.first{
  width: 50% !important;
}

.margin-left-cv{
  margin-left: 10px !important;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

}
.inputfile + label {
  font-size: 1rem;
  font-weight: 600;
  color: #040C2C;
  border: 1px solid #040C2C;
  display: inline-block;
  padding: 14px 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 5px;
  font-family:     "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #040C2C;
  color: white;
}

.inputfile + label {
	cursor: pointer; 
}
.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
	pointer-events: none;
}
.input-div{
  margin-top: 9px;
  margin-left: 10px;
}
a{
  color: black !important;
  text-decoration: none;
}
.Contact-icon{
  width: 7%;
}
.react-whatsapp{
  text-decoration: none;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  
}
.react-whatsapp:hover{
  color: 	#25D366;
  font-weight: 700;
}



.footer-logo-link{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.logo-width{
  width: 13%;
}
.footer-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 20px;
}
.Social-links{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.icon{
 
  width: 30px;
  height: 30px;
  margin: 5px;
  transition: transform .2s;
  cursor: pointer;
}
.icon:hover{
  transform: scale(1.5);
}

@media (max-width: 700px) {
  .slider{
    margin: 25px !important;
  }
  .companyLogo{
    flex-direction: column;
    padding-bottom: 0 !important;
    padding-top: 25px !important;
  }
  .logoImg{
    width: 50%;
    margin: 20px;
  }
  .contactInfo{
    display: flex !important;
    justify-content: space-evenly !important;
    height: 200px !important;
    align-items: center;
    padding-top: 20px !important;
   
  }
  .Info{
    display: flex;
   padding-left: 10px;
 
    
   
  }
  .Contact-icon{
    width: 10%;
  }

  .logo-width{
    width: 30%;
  }


}

